import "./style.css";
import { Feature, Map, Overlay, View } from "ol/index.js";
import { OSM, Vector as VectorSource } from "ol/source.js";
import { Point } from "ol/geom.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { useGeographic } from "ol/proj.js";
import { Icon, Style } from "ol/style.js";

useGeographic();

var params = new URLSearchParams(document.location.search);
var name = params.get("name");
var address = params.get("address");
var key = params.get("key");
var coordinates = params.get("coordinates");
// console.log("coordinates 1:", coordinates);
if (coordinates) {
  coordinates = coordinates.split(",");
}
// console.log("coordinates 2:", coordinates);
var zoom = params.get("zoom");
if (!zoom) {
  zoom = 16;
}

console.log("document.referrer:", document.referrer);
if (!document.referrer) {
  alert("Error: Only allowed in iframes");
}
if (!key) {
  alert("Error: Wrong map key");
}

// const place = [13.0398904, 47.7360913];
const place = [coordinates[0], coordinates[1]];

// const point = new Point(place);

const iconFeature = new Feature({
  geometry: new Point(place),
  name: "Null Island",
  population: 4000,
  rainfall: 500,
});

const iconStyle = new Style({
  image: new Icon({
    // anchor: [0.5, 46],
    // anchorXUnits: "fraction",
    // anchorYUnits: "pixels",
    src: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon-2x.png",
    scale: 0.5,
  }),
});
iconFeature.setStyle(iconStyle);

const map = new Map({
  target: "map",
  view: new View({
    center: place,
    zoom,
  }),
  layers: [
    // rasterLayer,
    new TileLayer({
      source: new OSM(),
    }),
    new VectorLayer({
      source: new VectorSource({
        features: [iconFeature],
      }),
      style: {
        "circle-radius": 9,
        "circle-fill-color": "red",
      },
    }),
  ],
});

const element = document.getElementById("popup");

const popup = new Overlay({
  element: element,
  stopEvent: false,
});
map.addOverlay(popup);

const info = document.getElementById("info");
info.innerHTML = `<table>
<tbody>
  <tr><td>${name}</td></tr>
  <tr><td>${address}</td></tr>
</tbody>
</table>`;

// let popover;
let showOverlay = true;
map.on("click", function (event) {
  if (showOverlay) {
    overlay.setPosition(undefined);
    showOverlay = false;
  } else {
    overlay.setPosition(coordinates);
    showOverlay = true;
  }
});

map.on("pointermove", function (event) {
  const type = map.hasFeatureAtPixel(event.pixel) ? "pointer" : "inherit";
  map.getViewport().style.cursor = type;
});

var overlay = new Overlay({
  element: info,
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});
overlay.setPosition(coordinates);
map.addOverlay(overlay);
